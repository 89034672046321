import { lazy, Suspense } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Styles } from "../styles/styles";

const HomePage = lazy(() => import(`../pages/Home`));

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <HomePage />
      <Footer />
    </Suspense>
  );
};

export default Router;
